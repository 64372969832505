<template>
  <div class="my-card">
    <van-nav-bar
      :title="
        this.$route.params.id == 1
          ? $t('Funds.bankCard')
          : this.$route.params.id == 3
          ? 'USDT address'
          : 'BTC'
      "
      left-arrow
      @click-left="$router.go(-1)"
      fixed
    />
    <div class="my-card-all">
      <van-cell :border="false" class="my-card-all-add">
        <template #title>
          <div>
            <span class="my-card-all-title">{{ $t("app.myCard") }}</span>
            <span class="my-card-all-label">{{ `(${myCardAll.length})` }}</span>
          </div>
        </template>
        <template #default>
          <div>
            <van-tag
              @click="onAdd"
              type="primary"
              size="medium"
              round
              color="#13b5b1"
              ><van-icon name="plus" />{{ $t("app.add") }}</van-tag
            >
          </div>
        </template>
      </van-cell>
      <div class="my-card-all-bank-card">
        <Loading v-if="showLoading"></Loading>
        <!-- <div class="loading">
          <van-loading v-if="showLoading" type="spinner" color="#13b5b1" />
        </div> -->
        <van-cell
          v-for="item in myCardAll"
          :key="item.BankId"
          :title="item.BankName"
          :label="item.BranchName"
          :border="false"
          class="my-card-all-first"
          :class="
            item.Type == 1
              ? 'china-bank'
              : item.Type == 2
              ? 'county-bank'
              : item.Type == 3
              ? 'another-bank'
              : 'county-bank'
          "
        >
          <template #icon>
            <van-image
              fit="cover"
              round
              width=".8rem"
              height=".8rem"
              :src="
                item.Type == 1
                  ? require('@/assets/image/all-bank.png')
                  : item.Type == 2
                  ? require('@/assets/image/b-logo.png')
                  : item.Type == 3
                  ? require('@/assets/image/t-logo.png')
                  : 'county-bank'
              "
            />
          </template>
          <template #default>
            <div class="cardid">
              <p class="cardid-p">{{ item.CardID }}</p>
            </div>
            <!-- <div class="my-card-bank-value">
              <span class="hide-code">****</span>
              <span class="show-code">{{ item.CardID | getBankCode }}</span>
            </div> -->
            <van-tag
              type="primary"
              size="medium"
              color="rgba(244, 245, 247, .3)"
              @click="onEdit(item.BankId, item.Type)"
              >{{ $t("Funds.edit") }}
              <van-icon class="iconfont" class-prefix="icon" name="bianji"
            /></van-tag>
          </template>
        </van-cell>
      </div>
    </div>
    <van-action-sheet
      v-model="CardAdd.show"
      :actions="CardAdd.actions"
      @select="cardAddOnSelect"
      :cancel-text="$t('Menu.cancel')"
    />
  </div>
</template>

<script>
import { GetBankByType } from "@/api";
export default {
  name: "MyCard",
  components: {
    Loading: () => import("@/components/crm/Loading.vue"),
  },
  data() {
    return {
      myCardAll: [],
      CardAdd: {
        show: false,
        actions: [
          { id: 1, name: this.$t("Funds.bankCard") },
          { id: 2, name: "BTC" },
          { id: 3, name: "USDT" },
        ],
      },
      showLoading: true,
    };
  },
  filters: {
    getBankCode(val) {
      if (!val) return "";
      val = val.toString().replaceAll(" ", "");
      return val.substring(val.length - 4);
    },
  },
  created() {
    this.getUserBank();
  },
  methods: {
    // async getAllBank() {
    //   // let data = { login: 0 };
    //   let res = await getBank(data);
    //   this.myCardAll = res.msg;
    // },
    getUserBank() {
      let data = { type: this.$route.params.id };
      GetBankByType(data).then((res) => {
          if (res.code != 200) {
              this.showLoading = false;
          //this.$router.push('/funds/bankinfo');
        } else {
          this.showLoading = false;
          var json = res.msg;
          console.log(json);
          for (var i = 0; i < json.length; i++) {
            this.myCardAll.push({
              BankAddress: json[i].BankAddress,
              BankId: json[i].BankId,
              BankName: json[i].BankName,
              BranchName: json[i].BranchName,
              UserName: json[i].UserName,
              CardID: json[i].CardID,
              Type: json[i].Type,
            });
          }
        }
      });
    },
    onEdit(id, type) {
      let url = "/funds/bankedit?bid=" + id + "&type=" + type;
      this.$router.push({
        path: url,
      });
    },
    cardAddOnSelect(item) {
      this.CardAdd.show = false;
      let url = "/funds/bankadd?type=" + item.id;
      this.$router.push({
        path: url,
      });
    },
    onAdd() {
      // this.CardAdd.show = true;
      this.$router.push(`/funds/bankadd?type=${this.$route.params.id}`);
    },
  },
};
</script>

<style scoped>
.my-card {
  overflow: auto;
  background-color: #f7f8fa;
}
.my-card-all-add {
  background-color: transparent;
}
.my-card-all {
  padding: 12px;
  margin-top: 50px;
}
.my-card-all-title {
  font-size: 14px;
  color: #000;
  font-weight: 700;
}
.my-card-all-label {
  font-size: 14px;
  color: #999;
}
.my-card-all-first ~ .my-card-all-first {
  margin-top: 12px;
}
.my-card-all .van-image {
  margin-right: 0.2rem;
  padding: 8px;
  background-color: #fff;
}
.my-card-all-first {
  border-radius: 8px;
  padding-bottom: 80px;
  color: #fff;
  background-image: linear-gradient(to right, skyblue 10%, #489dd4, 50%);
}
.china-bank {
  /* background-image: linear-gradient(
    to right,
    rgb(226, 68, 6) 10%,
    rgb(245, 52, 52) 50%
  ); */
  background: url("../../assets/image/visa-bank.png") no-repeat;
  background-position: -270px -72px;
  color: rgb(244, 245, 247);
}
.county-bank {
  /* background-image: linear-gradient(to right, skyblue 10%, #489dd4, 50%); */
  background: url("../../assets/image/b-back.png") no-repeat;
  background-position: -218px -57px;
}
.another-bank {
  /* background-image: linear-gradient(to right, skyblue 10%, #489dd4 50%); */
  background: url("../../assets/image/tether-bank.png") no-repeat;
  background-position: -218px -57px;
}
.my-card-all-bank-card {
  padding: 10px 14px;
}
.my-card-all >>> .van-cell__label {
  color: #fff;
}
.my-card-bank-value {
  color: #fff;
}
.my-card-all-first .van-tag {
  font-size: 10px;
}
.icon-bianji {
  margin-left: 2px;
}
.my-card-bank-value {
  position: relative;
}
.hide-code {
  position: absolute;
  top: 2px;
  right: 34px;
}
.my-card-all-label {
  white-space: nowrap;
}
.van-cell >>> .van-cell__label {
  white-space: nowrap;
}
.my-card-all-first {
  display: flex;
}
.my-card-all-first >>> .van-cell__value {
  overflow: unset;
}
.my-card-all-first >>> .cardid {
  color: #fff;
  position: absolute;
  top: 67px;
  left: -180px;
  padding: 0 14px;
  white-space: wrap;
}
.cardid-p {
  width: 290px;
  text-align: left;
}
/* .loading {
  display: flex;
  justify-content: center;
} */
</style>