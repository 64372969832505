var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-card"},[_c('van-nav-bar',{attrs:{"title":this.$route.params.id == 1
        ? _vm.$t('Funds.bankCard')
        : this.$route.params.id == 3
        ? 'USDT address'
        : 'BTC',"left-arrow":"","fixed":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"my-card-all"},[_c('van-cell',{staticClass:"my-card-all-add",attrs:{"border":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_c('span',{staticClass:"my-card-all-title"},[_vm._v(_vm._s(_vm.$t("app.myCard")))]),_c('span',{staticClass:"my-card-all-label"},[_vm._v(_vm._s(("(" + (_vm.myCardAll.length) + ")")))])])]},proxy:true},{key:"default",fn:function(){return [_c('div',[_c('van-tag',{attrs:{"type":"primary","size":"medium","round":"","color":"#13b5b1"},on:{"click":_vm.onAdd}},[_c('van-icon',{attrs:{"name":"plus"}}),_vm._v(_vm._s(_vm.$t("app.add")))],1)],1)]},proxy:true}])}),_c('div',{staticClass:"my-card-all-bank-card"},[(_vm.showLoading)?_c('Loading'):_vm._e(),_vm._l((_vm.myCardAll),function(item){return _c('van-cell',{key:item.BankId,staticClass:"my-card-all-first",class:item.Type == 1
            ? 'china-bank'
            : item.Type == 2
            ? 'county-bank'
            : item.Type == 3
            ? 'another-bank'
            : 'county-bank',attrs:{"title":item.BankName,"label":item.BranchName,"border":false},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('van-image',{attrs:{"fit":"cover","round":"","width":".8rem","height":".8rem","src":item.Type == 1
                ? require('@/assets/image/all-bank.png')
                : item.Type == 2
                ? require('@/assets/image/b-logo.png')
                : item.Type == 3
                ? require('@/assets/image/t-logo.png')
                : 'county-bank'}})]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"cardid"},[_c('p',{staticClass:"cardid-p"},[_vm._v(_vm._s(item.CardID))])]),_c('van-tag',{attrs:{"type":"primary","size":"medium","color":"rgba(244, 245, 247, .3)"},on:{"click":function($event){return _vm.onEdit(item.BankId, item.Type)}}},[_vm._v(_vm._s(_vm.$t("Funds.edit"))+" "),_c('van-icon',{staticClass:"iconfont",attrs:{"class-prefix":"icon","name":"bianji"}})],1)]},proxy:true}],null,true)})})],2)],1),_c('van-action-sheet',{attrs:{"actions":_vm.CardAdd.actions,"cancel-text":_vm.$t('Menu.cancel')},on:{"select":_vm.cardAddOnSelect},model:{value:(_vm.CardAdd.show),callback:function ($$v) {_vm.$set(_vm.CardAdd, "show", $$v)},expression:"CardAdd.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }